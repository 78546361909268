<template>
  <div class="cpt-MMC_Gimbal_R3" :style="containerStyle">
    <div v-interact class="hd">
      <div class="left ml8">
        <!-- <img src="~@/assets/images/mount/mount_head.png" /> -->
        <div class="title">手机信号检测</div>
      </div>
      <div class="close" @click="$emit('close')">关闭</div>
    </div>
    <div class='cf f12 ml20 mt5'>
        <span>持续更新</span><span></span> <span>排序依据:搜索时间</span>
      </div>
    <div class="bd">
      <el-table :data="tableData" style="width: 100%" border>
          <el-table-column width="140"prop="sn" label="无人机序列号" align="center"> </el-table-column>
          <el-table-column width="160"prop="imsi" label="移动用户识别码" align="center"> </el-table-column>
          <el-table-column width="50" prop="power" label="强度" align="center"> </el-table-column>
          <el-table-column width="90" prop="frequency" label="频点" align="center"> </el-table-column>
          <el-table-column width="60" prop="mno" label="运营商" align="center"> </el-table-column>
          <el-table-column prop="longitude" label="经度" align="center"> </el-table-column>
          <el-table-column prop="latitude" label="纬度" align="center"> </el-table-column>
          <el-table-column width="50" prop="altitude" label="海拔高度" align="center"> </el-table-column>
          <el-table-column width="50" prop="heading" label="航向" align="center"> </el-table-column>

        </el-table>
      <!-- <div class="form-wrap">
        <div class="form-item">
          <div class="label-box">水面距离:</div>
          <div class="input-box">
            <div class="text"><div class="green">{{cnswy1.elevationAnomaly}}m </div></div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">水面流速:</div>
          <div class="input-box">
            <div class="text"><div class="green">{{cnswy1.yl6}}m </div></div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">水位:</div>
          <div class="input-box">
            <div class="text"><div class="green">{{cnswy1.waterLevel}}m </div></div>
          </div>
        </div>
      </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { LoginAPI, User } from "@/api";
export default {
  props: {},
  data() {
    return {
      tableData: [{
  "id": 1,
  "sn": "0X67OP7UXR2",     
  "imsi":"460003737848478",
  "power":90,               
  "frequency":38900,       
  "mno":"移动",            
  "latitude":22.489987,    
  "longitude":113.343442,  
  "altitude":100,           
  "heading":355,           
  "createTime":"2022-01-01 13:01:01"
}],
        payload:""
    };
  },
  // watch: {
  //   "$store.state.uavApplications.mountList": {
  //     async handler(value) {
  //       if (value) {
  //         const mount = value.find((item) => item.gimbalName === "MMC_Gimbal_R3");
  //         if(mount&&mount.payload){
  //           this.payload=mount.payload
  //       }
  //     }
  //     },
  //     deep: true,
  //     immediate: true,
  //   },
  // },
  beforeDestroy() {
  },
  mounted() {

  },
  created() {
    this.login()
  },
  methods: {
  async  login(){
    const loginInfo= await  LoginAPI.nasasLogin({account:"admin","password": "123456"})
    console.log(loginInfo,'loginInfo');
    }
  },
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_R3 {
  position: absolute;
  top: -1px;
  right: 0;
  background: #262a2d;
  width: 838px;
  height: 290px;
  // height: 418px;
  box-sizing: border-box;

  // padding: 10px 20px;
  .hd {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #262a2d;
    border-bottom: 1px solid #404346;
    .left {
      display: flex;
      align-items: center;

      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #ffffff;
        line-height: 26px;
        // text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        // background: linear-gradient(
        //   135deg,
        //   #e3aa77 0%,
        //   #f5cda9 38%,
        //   #f9ecd3 58%,
        //   #fcdbb1 79%,
        //   #edb07a 100%
        // );
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .bd {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    height: 230px;
    overflow-x: hidden;
    .form-wrap {
      flex-shrink: 0;

      .form-item {
        display: flex;
        align-items: center;
        height: 40px;

        .label-box {
          color: #cbd0eb;
          width: 75px;
        }

        .btn-default {
          padding: 0 10px !important;
          background: #004fff;
          border-color: #004fff;
        }

        .input-box {
          display: flex;
          align-items: center;

          .mono {
            display: flex;
            flex-direction: column;

            .mono-long {
              display: flex;
              justify-content: space-between;
              width: 168px;
              height: 17px;
              background: url("~@/assets/images/mount_long.png") no-repeat;
              background-size: 100%;
              margin-bottom: 5px;

              .mono_box {
                width: 28px;
                height: 13px;
                border-radius: 3px;
                margin-top: 2px;
                cursor: pointer;
              }

              .active {
                background: url("~@/assets/images/mount/mount_control.png")
                  no-repeat !important;
                background-size: 100% 100%;
              }
            }

            .text {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #ffffff;
              display: flex;
              justify-content: space-between;
              font-size: 14px;
            }
          }
        }
      }
    }

    .rocker-wrap {
      flex: 1;

      .item-group {
        display: flex;
        justify-content: space-evenly;

        .item-box {
          .icon-box {
            width: 32px;
            height: 32px;
            background: #004fff;
            border-radius: 32px;
            display: flex;
            align-items: center;
            justify-content: center;

            .iconfont {
              font-size: 24px;
              color: #dce9ff;
              cursor: pointer;
            }
          }
        }
      }

      .rocker {
        margin: 10px auto 0;
        width: 150px;
        height: 150px;
        position: relative;
        margin: 0 auto;
        background: center url("~@/assets/images/accident/caozuo.png") no-repeat;
        background-size: 100% 100%;

        .shangUp {
          position: absolute;
          left: 37%;
          top: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .xaiUp {
          position: absolute;
          left: 37%;
          bottom: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .zuoUp {
          position: absolute;
          left: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .youUp {
          position: absolute;
          right: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
      }
    }
  }
}

::v-deep {
  .el-select {
    width: 165px;
    margin-right: 5px;

    .el-input {
      .el-input__inner {
        background: #000000;
        border: 1px solid #004fff;
        color: #dce9ff;
      }
    }
  }

  .el-input {
    width: 165px;
    margin-right: 5px;

    .el-input__inner {
      background: #000000;
      border: 1px solid #004fff;
      color: #dce9ff;
    }
  }

  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #004fff;
            background: #004fff;
          }
        }
      }

      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }

  .el-button {
    background: #004fff;
    color: #000;
    border: none;
  }
}

::v-deep .el-slider__bar {
  background: #fff;
}

.text {
  width: 203px;
  height: 24px;
  display: flex;
  background: rgba(13, 34, 79, 0.65);
  border: 1px solid #004fff;
  border-radius: 12px;
  text-indent: 1rem;
 color: #fff;
  .speed {
    width: 41px;
    // background: #FFFFFF;
    color: #ffffff;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
  }

  .pitch {
    font-size: 12px;
    margin-left: 12px;
    width: 34px;
    height: 24px;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
    color: #fff;
  }
  .green{
    color:#00eb02
  }
  .blue{
    color:#096ebe
  }
  .yellow{
    color:#b4b428
  }
  .red{
    color:#d42008
  }
}

.iconfont {
  color: #004fff;
}

// 变焦功能
::v-deep .el-slider__runway {
  visibility: hidden;
}
::v-deep{
  .el-table--border .el-table__cell {
    border-right-color: #3e4246
  }
  .el-table th.el-table__cell {
    background: #3e4246 ;
}
}
</style>
